import React from "react";
import {
  ABOUT_IMAGE,
  ABOUT_FLOAT_HORIZONTAL_ICON,
} from "../../constants/images/images.c";

/**
 * Props for the Title component.
 * Defines the subtitle and title to be displayed.
 */
interface TitleProps {
  subtitle: string;
  title: string;
}

/**
 * Component responsible for rendering the title with a subtitle.
 *
 * @param subtitle - The subtitle text.
 * @param title - The main title text.
 * @returns JSX.Element that represents the title.
 */
const Title: React.FC<TitleProps> = ({ subtitle, title }) => (
  <div className="about-title second-title pb-25">
    <h5>{subtitle}</h5>
    <h2>{title}</h2>
  </div>
);

/**
 * Props for the Paragraph component.
 * Defines the text and optional CSS class to be applied.
 */
interface ParagraphProps {
  text: string;
  className?: string;
}

/**
 * Component responsible for rendering a paragraph with optional class.
 *
 * @param text - The text to be displayed in the paragraph.
 * @param className - Optional CSS class for the paragraph.
 * @returns JSX.Element that represents a paragraph.
 */
const Paragraph: React.FC<ParagraphProps> = ({ text, className = "" }) => (
  <p className={className}>{text}</p>
);

/**
 * Component responsible for rendering the main content in the About section.
 * It includes the title, paragraphs, and highlights.
 *
 * @returns JSX.Element that represents the About content.
 */
const AboutContent: React.FC = () => {
  return (
    <div
      className="about-content s-about-content pl-15 wow fadeInRight animated"
      data-animation="fadeInRight"
      data-delay=".4s"
    >
      <Title
        subtitle="Sobre nós"
        title="Guiando Suas Escolhas Para o Melhor Caminho"
      />
      <Paragraph
        className="txt-clr"
        text="A Ostrya Investimentos é uma consultoria de investimentos independente e comprometida em oferecer as melhores opções de investimento sem receber comissões ou incentivos de corretoras ou bancos. Nossa remuneração é transparente e negociada diretamente com o investidor, garantindo um serviço alinhado aos seus objetivos."
      />
      <Paragraph text="Inspirados na resistência da árvore Ostrya, conhecida como Ironwood, acreditamos que, assim como as árvores, os investimentos precisam de cuidado contínuo para crescerem e darem frutos ao longo do tempo. Com mais de uma década de experiência no mercado financeiro, nossa missão é ajudar cada investidor a cultivar seus recursos de forma sólida, visando a criação de riqueza a médio e longo prazo." />
    </div>
  );
};

/**
 * Component responsible for rendering the image and content in the About section.
 *
 * @returns JSX.Element that represents the image content.
 */
const ImageContent: React.FC = () => {
  return (
    <div className="about-text second-about">
      <span>
        10 <sub>+</sub>
      </span>
      <p>Anos de experiência no mercado financeiro</p>
    </div>
  );
};

/**
 * Props for the ImageArea component.
 * Defines the children elements to be passed inside the image area.
 */
interface ImageAreaProps {
  children: React.ReactNode;
}

/**
 * Component responsible for rendering the image area that wraps the content.
 *
 * @param children - The content to be displayed inside the image area.
 * @returns JSX.Element that represents the image area.
 */
const ImageArea: React.FC<ImageAreaProps> = ({ children }) => {
  return (
    <div
      className="s-about-img p-relative wow fadeInLeft animated"
      data-animation="fadeInLeft"
      data-delay=".4s"
    >
      {children}
    </div>
  );
};

/**
 * Component responsible for rendering the image in the About section.
 *
 * @returns JSX.Element that represents the About image.
 */
const AboutImage: React.FC = () => (
  <ImageArea>
    <img src={ABOUT_IMAGE} alt="Sobre nós" />
    <ImageContent />
  </ImageArea>
);

/**
 * Component that defines the structure and layout of the About section.
 *
 * @returns JSX.Element that represents the entire About section.
 */
const AboutSection: React.FC = () => (
  <section
    id="about"
    className="about-area about-p pt-120 pb-120 p-relative fix"
    style={{ background: "#eff7ff" }}
  >
    <div className="animations-02">
      <img src={ABOUT_FLOAT_HORIZONTAL_ICON} alt="Logo da Ostrya flutuando" />
    </div>

    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <AboutImage />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <AboutContent />
        </div>
      </div>
    </div>
  </section>
);

/**
 * Main About component that renders the entire AboutSection.
 *
 * @returns JSX.Element that represents the About page.
 */
const About: React.FC = () => <AboutSection />;

export default About;
