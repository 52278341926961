import { useState, ChangeEvent, FormEvent } from "react";
import axios from "axios";

/**
 * Type definition for the form data object
 */
interface FormData {
  [key: string]: string;
}

/**
 * Custom hook to manage form data.
 *
 * @param initialState - The initial state of the form data.
 * @returns An object containing the form data, updateField function, and resetForm function.
 */
const useForm = (initialState: FormData) => {
  const [formData, setFormData] = useState<FormData>(initialState);

  const updateField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const resetForm = () => setFormData(initialState);

  return {
    formData,
    updateField,
    resetForm,
  };
};

/**
 * Checks if the form data is valid by ensuring no fields are empty.
 *
 * @param formData - The form data object.
 * @returns True if all fields are filled, otherwise false.
 */
const isFormValid = (formData: any): boolean => {
  // Ensure that formData is an object before trying to validate
  if (typeof formData !== "object" || formData === null) {
    return false;
  }

  return Object.values(formData).every(
    (value) => typeof value === "string" && value.trim() !== ""
  );
};

/**
 * Submits the form data to the backend.
 *
 * @param formData - The form data object.
 * @returns The response data from the server.
 */
const submitForm = async (formData: FormData) => {
  const response = await axios.post(
    "https://enchanting-ambition-production.up.railway.app/send",
    formData
  );

  return response.data;
};

/**
 * Custom hook to handle the newsletter form submission.
 *
 * @param initialState - The initial state of the form data.
 * @returns An object containing form data, error message, updateField function, and handleSubmit function.
 */
const useNewsletterForm = (initialState: FormData) => {
  const { formData, updateField, resetForm } = useForm(initialState);
  const [error, setError] = useState<string>("");

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    onSuccess?: (message: string) => void,
    onError?: () => void
  ) => {
    e.preventDefault();

    if (!isFormValid(formData)) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    try {
      const response = await submitForm(formData);
      if (!response.success) {
        setError("Ocorreu um erro ao enviar o formulário.");
        onError?.();
        return;
      }

      resetForm();
      setError("");
      onSuccess?.("Formulário enviado!");
      alert("Inscrito(a) com sucesso!");
    } catch (err) {
      console.error("Erro ao enviar o formulário:", err);
      setError("Erro ao enviar o formulário.");
      onError?.();
    }
  };

  return {
    formData,
    error,
    updateField,
    handleSubmit,
  };
};

export default useNewsletterForm;
