import Button from "../commons/Button";
import { WHATSAPP_LINK } from "../../constants/links/links.c";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { serviceItems } from "../../constants/data/serviceItems";
import { HERO_IMAGE } from "../../constants/images/images.c";

/**
 * TextContent Component
 *
 * A stateless functional component that renders the welcome text, a headline,
 * and a description for the hero section of the website. The text includes animations
 * for visual appeal.
 *
 * @returns {JSX.Element} - The JSX for the text portion of the hero section.
 */
const TextContent = () => (
  <>
    <h5 data-animation="fadeInUp" data-delay=".4s">
      Bem-vindo à Ostrya
    </h5>
    <h2 data-animation="fadeInUp" data-delay=".4s">
      Cuidamos do seu patrimônio com transparência e independência
    </h2>
    <p data-animation="fadeInUp" data-delay=".6s">
      A Ostrya Investimentos é uma consultoria independente, comprometida em
      oferecer as melhores opções de investimentos para o seu perfil.
    </p>
  </>
);

/**
 * CallToActionButton Component
 *
 * Renders a call-to-action button in the hero section that directs users to
 * WhatsApp for immediate consultation. Utilizes a reusable `Button` component.
 *
 * @returns {JSX.Element} - The JSX for the call-to-action button with WhatsApp integration.
 */
const CallToActionButton = () => (
  <div className="slider-btn mt-30">
    <Button
      href={WHATSAPP_LINK}
      leftIconClassName="fab fa-whatsapp icon-mr"
      buttonClassName="btn ss-btn mr-15"
      buttonText="Fale Agora Com um Consultor"
    />
  </div>
);

/**
 * HeroContent Component
 *
 * Combines the `TextContent` and `CallToActionButton` components to form
 * the full content block for the hero section, including positioning using a grid system.
 *
 * @returns {JSX.Element} - The JSX for the content block of the hero section.
 */
const HeroContent = () => (
  <div className="container">
    <div className="row">
      <div className="col-lg-7 col-md-7">
        <div className="slider-content s-slider-content mt-130">
          <TextContent />
          <CallToActionButton />
        </div>
      </div>
      <div className="col-lg-5 col-md-5 p-relative"></div>
    </div>
  </div>
);

/**
 * ServiceIcon Component
 *
 * Renders the icon associated with a service in the service items section.
 *
 * @param {object} props - The properties for the ServiceIcon component.
 * @param {string} props.icon - The path to the icon image.
 * @param {string} props.iconAlt - The alt text for the icon image.
 *
 * @returns {JSX.Element} - The JSX for a service item icon.
 */
interface ServiceIconProps {
  icon: string;
  iconAlt: string;
}

const ServiceIcon: React.FC<ServiceIconProps> = ({
  icon: imageIcon,
  iconAlt: imageAlt,
}) => (
  <div className="icon d-flex justify-content-center align-items-start me-3">
    <img
      src={imageIcon}
      alt={imageAlt}
      className="img-fluid"
      style={{ maxWidth: "50px" }}
    />
  </div>
);

/**
 * ServiceText Component
 *
 * Renders the title, description, and a link for a service in the service items section.
 *
 * @param {object} props - The properties for the ServiceText component.
 * @param {string} props.to - The URL for the service's detailed page.
 * @param {string} props.title - The title of the service.
 * @param {string} props.description - A short description of the service.
 * @param {string} [props.btnText="Saiba Mais"] - The text for the link button.
 * @param {string} [props.btnIcon="fal fa-long-arrow-right"] - The icon class for the link button.
 *
 * @returns {JSX.Element} - The JSX for a service item text block.
 */
interface ServiceTextProps {
  to: string;
  title: string;
  description: string;
  btnText?: string;
  btnIcon?: string;
}

const ServiceText: React.FC<ServiceTextProps> = ({
  to = "",
  title = "",
  description = "",
  btnText = "Saiba Mais",
  btnIcon = "fal fa-long-arrow-right",
}) => (
  <div className="text flex-grow-1">
    <h5>
      <Link to={to}>{title}</Link>
    </h5>
    <p>{description}</p>
    <div className="mt-auto">
      <div className="mt-3">
        <Link className="mt-2" to={to}>
          {btnText} <i className={`${btnIcon} icon-ml`} />
        </Link>
      </div>
    </div>
  </div>
);

/**
 * ServiceBox Component
 *
 * Combines both the `ServiceIcon` and `ServiceText` components to render a full service box
 * that includes an icon, title, description, and a link.
 *
 * @param {object} props - The properties for the ServiceBox component.
 *
 * @returns {JSX.Element} - The JSX for a complete service item card.
 */
interface ServiceBoxProps extends ServiceIconProps, ServiceTextProps {}

const ServiceBox: React.FC<ServiceBoxProps> = ({
  icon,
  iconAlt,
  title,
  description,
  to,
  btnText,
}) => (
  <div className="services-box07 card h-100 bg-oc-primary">
    <div className="sr-contner card-body d-flex flex-column">
      <div className="d-flex align-items-start">
        <ServiceIcon icon={icon} iconAlt={iconAlt} />
        <ServiceText
          title={title}
          description={description}
          to={to}
          btnText={btnText}
        />
      </div>
    </div>
  </div>
);

/**
 * ServiceDetails Component
 *
 * Iterates through the service items and renders each one using the `ServiceBox` component.
 *
 * @returns {JSX.Element} - The JSX for displaying all service items in a grid.
 */
const ServiceDetails: React.FC = () => (
  <section className="service-details-two p-relative">
    <div className="container">
      <div className="row">
        {serviceItems.map((service, index) => (
          <div key={index} className="col-lg-4 col-md-12 col-sm-12 mt-2">
            <ServiceBox
              title={service.title}
              description={service.description}
              to={service.to}
              icon={service.icon}
              iconAlt={service.title}
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

/**
 * SliderOne Component
 *
 * Renders the main hero slider section. The slider uses custom settings and includes
 * controls for navigating between slides. Each slide contains a hero image with overlaying text.
 *
 * @returns {JSX.Element} - The JSX for the hero slider section.
 */
const SliderOne: React.FC = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      { breakpoint: 1200, settings: { dots: false, arrows: false } },
    ],
  };

  return (
    <section id="home" className="slider-area fix p-relative">
      <div style={{ background: "#141b22" }}>
        <Slider className="slider-active" {...settings}>
          <div>
            <div
              className="single-slider slider-bg"
              style={{
                backgroundImage: `url(${HERO_IMAGE})`,
                backgroundSize: "cover",
              }}
            >
              <HeroContent />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

/**
 * SampleNextArrow Component
 *
 * Renders the custom button for navigating to the next slide in the slider.
 *
 * @param {object} props - The properties for the arrow button.
 *
 * @returns {JSX.Element} - The JSX for the next slide button.
 */
interface SampleArrowsProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const SampleNextArrow: React.FC<SampleArrowsProps> = ({
  className,
  style,
  onClick,
}) => (
  <button
    type="button"
    className={`slick-next ${className}`}
    style={{ ...style }}
    onClick={onClick}
  >
    <i className="far fa-angle-right"></i>
  </button>
);

/**
 * SamplePrevArrow Component
 *
 * Renders the custom button for navigating to the previous slide in the slider.
 *
 * @param {object} props - The properties for the arrow button.
 *
 * @returns {JSX.Element} - The JSX for the previous slide button.
 */
const SamplePrevArrow: React.FC<SampleArrowsProps> = ({
  className,
  style,
  onClick,
}) => (
  <button
    type="button"
    className={`slick-prev ${className}`}
    style={{ ...style }}
    onClick={onClick}
  >
    <i className="far fa-angle-left"></i>
  </button>
);

/**
 * Hero Component
 *
 * Combines the `SliderOne` and `ServiceDetails` components to render the full hero
 * and services section on the page. The `SliderOne` displays a slider with hero content
 * and the `ServiceDetails` showcases the list of services provided.
 *
 * @returns {JSX.Element} - The JSX for rendering the hero and services sections.
 */
const Hero: React.FC = () => (
  <div className="animated wow fadeInUp">
    <SliderOne />
    <ServiceDetails />
  </div>
);

export default Hero;
