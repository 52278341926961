// Planning.tsx
import React from "react";
import {
  PLANNING_ICON_01,
  PLANNING_ICON_02,
  PLANNING_ICON_03,
  PLANNING_IMAGE,
  PLANNING_VERTICAL_ICON,
} from "../../constants/images/images.c";

/**
 * Interface that defines the type for each feature item.
 * A feature contains an icon, a title, and a description.
 */
interface Feature {
  icon: string;
  title: string;
  description: string;
}

/**
 * Props for the FeatureItem component.
 * Defines the expected parameters when rendering an individual feature item.
 */
interface FeatureItemProps {
  icon: string;
  title: string;
  description: string;
}

/**
 * Props for the FeaturesList component.
 * Defines the array of feature items to be mapped and rendered.
 */
interface FeaturesListProps {
  features: Feature[];
}

/**
 * Component that represents an individual feature item in the list.
 *
 * @param icon - The icon to be displayed for the feature.
 * @param title - The title of the feature.
 * @param description - The description of the feature.
 * @returns JSX.Element that represents the feature item.
 */
const FeatureItem: React.FC<FeatureItemProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <li>
      <div
        className="step-box wow fadeInUp animated"
        data-animation="fadeInUp"
        data-delay=".4s"
      >
        <div className="dnumber">
          <div className="date-box">
            <img src={icon} alt="icon" />
          </div>
        </div>
        <div className="text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </li>
  );
};

/**
 * Component that renders a list of features.
 * It maps over the `features` array and renders each item using the FeatureItem component.
 *
 * @param features - Array of feature objects containing icon, title, and description.
 * @returns JSX.Element that represents the list of features.
 */
const FeaturesList: React.FC<FeaturesListProps> = ({ features }) => {
  return (
    <ul className="pr-20">
      {features.map((feature) => (
        <FeatureItem
          key={feature.icon}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </ul>
  );
};

// Array com os itens de recurso que serão passados para o componente FeaturesList
const features: Feature[] = [
  {
    icon: PLANNING_ICON_01,
    title: "Minimiza conflitos",
    description:
      "Reduz disputas entre herdeiros e garante que a vontade do titular seja cumprida.",
  },
  {
    icon: PLANNING_ICON_02,
    title: "Reduz impostos",
    description: "Diminui a carga tributária sobre a herança.",
  },
  {
    icon: PLANNING_ICON_03,
    title: "Simplifica processos",
    description:
      "Evita burocracias na transferência de bens e garante a continuidade de negócios.",
  },
];

/**
 * Component responsible for displaying the section title and description.
 *
 * @returns JSX.Element that represents the header of the Succession Planning section.
 */
const SectionTitle: React.FC = () => (
  <div
    className="section-title mb-35 wow fadeInDown animated"
    data-animation="fadeInDown"
    data-delay=".4s"
  >
    <h2>Planejamento Sucessório</h2>
    <p>
      A Ostrya Investimentos conta com uma equipe especializada pronta para
      auxiliar em todas as etapas do planejamento sucessório, garantindo que a
      distribuição de bens ocorra conforme os desejos do titular e de forma
      eficiente.
    </p>
  </div>
);

/**
 * Component responsible for displaying the step image in the planning section.
 *
 * @returns JSX.Element that renders the image related to the section.
 */
const StepImage: React.FC = () => (
  <div
    className="step-img wow fadeInLeft animated"
    data-animation="fadeInLeft"
    data-delay=".4s"
  >
    <img src={PLANNING_IMAGE} alt="Familia Reunida" />
  </div>
);

/**
 * Main component that combines all the elements of the Succession Planning section.
 *
 * This component includes the title, the features list, and the image, organizing them
 * within a responsive layout.
 *
 * @returns JSX.Element that represents the Succession Planning section in the page layout.
 */
const Planning: React.FC = () => (
  <section id="planning" className="my-5 bg-oc-primary steps-area p-relative">
    <div className="animations-10">
      <img src={PLANNING_VERTICAL_ICON} alt="Logo da Ostrya flutuando" />
    </div>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12">
          <SectionTitle />
          <FeaturesList features={features} />
        </div>
        <div className="col-lg-6 col-md-12">
          <StepImage />
        </div>
      </div>
    </div>
  </section>
);

export default Planning;
