import React from "react";
import { INSIGHT_CARD_01 } from "../../../constants/images/images.c";

function First() {
  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="upper-box">
            <div className="single-item-carousel owl-carousel owl-theme">
              <figure className="image">
                <img src={INSIGHT_CARD_01} alt="" />
              </figure>
            </div>
          </div>

          <div className="lower-content2">
            <div className="row">
              <div className="text-column col-lg-9 col-md-12 col-sm-12">
                <div
                  className="s-about-content wow fadeInRight"
                  data-animation="fadeInRight"
                  data-delay=".2s"
                >
                  <h2>Consultoria x Assessoria: Entenda a Diferença</h2>
                  <p>
                    A construção de riqueza ao longo do tempo depende da
                    disciplina do investidor em economizar parte da sua renda e
                    das decisões sobre onde alocar esses recursos. As opções de
                    investimento são inúmeras e vêm aumentando cada vez mais, o
                    que torna complexa a decisão de alocação.
                  </p>
                  <p>
                    Como resultado, muitos investidores, com medo de errar,
                    acabam aplicando os seus recursos em modalidades
                    excessivamente conservadoras em relação ao que o seu perfil
                    recomendaria, muitas vezes pagando um alto custo operacional
                    (comissões, taxa de administração, tributos, etc.) e,
                    consequentemente, obtendo um retorno abaixo daquele
                    esperado. Por outro lado, alguns investidores mais
                    aventureiros, acabam assumindo riscos acima do que seria
                    recomendável para o seu perfil, seja por desconhecimento ou
                    na busca por maiores retornos e, muitas vezes, acabam
                    incorrendo em perdas financeiras e se frustrando.
                  </p>
                  <p>
                    Assim, considerando a existência de uma grande variedade de
                    modalidades de investimentos disponíveis no mercado
                    financeiro e que, geralmente, o investidor não é um
                    especialista no assunto, é importante contar com um suporte
                    profissional para auxiliar na análise e na tomada de decisão
                    sobre os investimentos.
                  </p>

                  <p>
                    Neste sentido, três tipos de profissionais costumam
                    desempenhar esse papel: gerente bancário, assessor de
                    investimentos e consultor de investimentos.
                  </p>

                  <p>Vamos falar um pouco sobre cada um deles:</p>
                  <p>
                    O gerente bancário é um profissional que trabalha com uma
                    ampla gama de serviços financeiros, desde operações de
                    crédito, serviços financeiros para empresas (cobrança
                    bancária, folha de pagamento, etc.) e pessoas físicas,
                    seguros, planos de capitalização e, também, aplicações
                    financeiras. Considerando a diversidade de serviços
                    oferecidos, geralmente esse profissional é um generalista,
                    não um especialista, principalmente na área de
                    investimentos. Adicionalmente, os bancos costumam ter uma
                    plataforma de aplicações financeiras mais limitada do que
                    outros players do mercado, como as corretoras de valores. A
                    remuneração das aplicações financeiras é o custo dos
                    recursos captados pelos bancos, que são utilizados para
                    alocar em operações de crédito. Considerando uma mesma taxa
                    de juros de um empréstimo, quando menos o banco pagar para
                    os seus clientes aplicadores, maior será o spread e o ganho
                    do banco. Além disso, o gerente bancário costuma ser
                    direcionado por metas que, não necessariamente, estão
                    alinhadas com as melhores opções para os clientes. Isso pode
                    fazer com que o gerente bancário acabe direcionando os
                    recursos dos clientes para modalidades de investimentos que
                    não são as melhores para o investidor, mas que seja a melhor
                    para a instituição financeira.
                  </p>
                  <p>
                    Ao longo das últimas décadas, na esteira do desenvolvimento
                    do mercado financeiro e da ineficiência dos bancos em
                    proporcionar as melhores soluções para os investidores,
                    surgiu o agente autônomo de investimento, popularmente
                    conhecido como assessor de investimentos. Esses
                    profissionais trouxeram a concorrência ao mercado de
                    investimentos, até então dominado pelos bancos, apresentando
                    novas alternativas de aplicações financeiras e contribuindo
                    com a evolução desse mercado. Contudo, ao longo dos anos
                    ficou evidente a existência de um conflito de interesses
                    inerente à estrutura de remuneração dessa profissão. O
                    agente autônomo de investimentos é remunerado através de
                    comissões sobre as transações financeiras realizadas pelo
                    investidor. Assim, se o investidor compra uma ação hoje e
                    vende amanhã, o assessor foi remunerado duas vezes. Contudo,
                    se esse mesmo investidor comprou uma ação há 10 anos e
                    mantém essa posição até hoje, o assessor foi remunerado
                    apenas uma vez (na compra). Essa dinâmica muda de acordo com
                    a modalidade de investimento: muitos fundos de
                    investimentos, por exemplo, pagam comissão mensal aos
                    assessores, já os títulos de renda fixa privados, possuem
                    comissionamento distinto de acordo com o spread pago pelo
                    cliente, entre outros. Essa estrutura de remuneração, que
                    carece de maior transparência, impõe custos ao investidor
                    que ele não enxerga. Como resultado desta estrutura de
                    remuneração, que gera um conflito de interesses entre
                    assessor e investidor, muitas vezes as modalidades de
                    investimento ofertadas são aquelas que geram a maior
                    comissão (não necessariamente as mais adequadas para o
                    investidor) e que, também, podem resultar em uma maior
                    rotatividade dos ativos do portfólio (uma vez que uma parte
                    relevante das comissões é originada em cada negociação),
                    mesmo que não seja o mais indicado para os objetivos do
                    cliente. Além disso, os escritórios de assessores de
                    investimento costumam ter exclusividade com alguma corretora
                    e recebem bonificações financeiras dessas instituições,
                    aumentando o comprometimento dos profissionais com os
                    distribuidores. Embora existam ótimos profissionais
                    trabalhando como assessores de investimentos, muitos outros
                    profissionais deixam os incentivos financeiros conflitantes
                    guiarem as suas atividades em detrimento dos objetivos dos
                    clientes.
                  </p>

                  <p>
                    Ao longo dos últimos anos, o mercado financeiro vem passando
                    uma terceira etapa dessa evolução nos profissionais de
                    investimentos, com o surgimento dos{" "}
                    <strong>consultores de valores mobiliários</strong>. O
                    consultor, diferentemente dos profissionais apresentados
                    anteriormente, é contratado pelo investidor e não pela
                    instituição financeira ou pela corretora de valores. Assim,
                    o consultor possui total{" "}
                    <strong>autonomia de trabalho</strong>, não tendo nenhuma
                    obrigação de exclusividade com qualquer instituição
                    financeira ou corretora de valores, nem recebendo qualquer
                    remuneração, comissão ou bonificação dessas instituições.
                    Geralmente a remuneração acordada entre consultor e cliente
                    prevê um valor fixo ou um percentual sobre o patrimônio, o
                    que proporciona um{" "}
                    <strong>alinhamento de interesses</strong> financeiros entre
                    ambas as partes, além da liberdade de atuação profissional
                    já citada.
                  </p>

                  <p>
                    Os <strong>consultores de valores mobiliários</strong> da{" "}
                    <strong>Ostrya Investimentos</strong> devem cumprir{" "}
                    <strong>rigorosos padrões éticos</strong> e as{" "}
                    <strong>melhores práticas de conduta profissional</strong>,
                    previstos em nosso Código de Ética, possuem{" "}
                    <strong>
                      experiência de mercado, conhecimento técnico e alinhamento
                      de interesses
                    </strong>{" "}
                    com os nossos clientes. Nós temos uma visão de{" "}
                    <strong>relacionamento de longo prazo</strong>, estruturada
                    na <strong>confiança</strong> e na{" "}
                    <strong>qualidade</strong> no atendimento e nos serviços
                    prestados. Além de tudo isso, os nossos clientes que optarem
                    por trabalhar com empresas parceiras, receberão um{" "}
                    <strong>cashback</strong> de todas as comissões que seriam
                    pagas aos assessores, reduzindo assim o seu custo
                    operacional, tornando ainda mais vantajosa a relação
                    custo-benefício do nosso serviço.
                  </p>

                  <p>
                    Mesmo assim, caso você não esteja satisfeito, aqui na Ostrya
                    você pode cancelar nossos serviços a qualquer momento, sem
                    qualquer penalidade. O nosso compromisso é com o atingimento
                    dos seus objetivos, com o seu patrimônio e com a sua
                    família.
                  </p>
                  {/* <div className="two-column mt-30">
                    <div className="row aling-items-center">
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <div className="footer-social mt-10">
                          <a href="#">
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a href="#">
                            <i className="fab fa-instagram" />
                          </a>
                          <a href="#">
                            <i className="fab fa-twitter" />
                          </a>
                        </div>
                      </div>
                      <div className="text-column col-xl-6 col-lg-12 col-md-12 text-right"></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
