import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Header from "../components/Header/Header";
import HomePage from "../pages/HomePage";
import PlanningPage from "../pages/PlanningPage";
import MentoringPage from "../pages/MentoringPage";
import Footer from "../components/Footer/Footer";
import InvestmentsConsultingPage from "../pages/InvestmentsConsultingPage";
import InsightDetails from "../components/Insights/insights-details/InsightsDetails";

function Index() {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/consultoria-de-investimentos"
          element={<InvestmentsConsultingPage />}
        />
        <Route path="/planejamento-sucessorio" element={<PlanningPage />} />
        <Route path="/mentoria" element={<MentoringPage />} />
        <Route path="/insights/:insightId" element={<InsightDetails />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Index;
