import React from "react";
import useContactForm from "../../hooks/useContactForm";

// Interface defining the structure of the form data
interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

// Define a type for FAQ items
interface FAQItem {
  question: string;
  answer: string;
}

/**
 * InputField Component
 *
 * Renders an individual input field in the form, handling user input changes.
 *
 * @param {string} name - The name attribute of the input field.
 * @param {string} value - The current value of the input field.
 * @param {string} placeholder - Placeholder text to display in the input field.
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange - Handler function to update input value.
 * @param {boolean} required - Indicates if the field is required.
 * @returns {JSX.Element} An input field.
 */
const InputField: React.FC<{
  name: string;
  value: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
}> = ({ name, value, placeholder, onChange, required = true }) => (
  <div className="contact-field p-relative mb-20">
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
    />
  </div>
);

/**
 * TextAreaField Component
 *
 * Renders a textarea field for longer text inputs (e.g., message field).
 *
 * @param {string} name - The name attribute of the textarea field.
 * @param {string} value - The current value of the textarea field.
 * @param {string} placeholder - Placeholder text for the textarea.
 * @param {React.ChangeEventHandler<HTMLTextAreaElement>} onChange - Handler function to update input value.
 * @param {boolean} required - Indicates if the field is required.
 * @returns {JSX.Element} A textarea field.
 */
const TextAreaField: React.FC<{
  name: string;
  value: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  required?: boolean;
}> = ({ name, value, placeholder, onChange, required = true }) => (
  <div className="contact-field p-relative mb-30">
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      cols={30}
      rows={10}
      required={required}
    />
  </div>
);

/**
 * ContactForm Component
 *
 * Renders the contact form allowing users to submit their contact details and message.
 * It uses a custom hook (`useContactForm`) to manage form state and submission.
 *
 * @returns {JSX.Element} The contact form.
 */
const ContactForm: React.FC = () => {
  const initialState: FormData = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const { formData, status, updateField, handleSubmit } =
    useContactForm(initialState);

  // Array defining the form fields to be mapped
  const formFields = [
    { name: "name", type: "text", placeholder: "Nome" },
    { name: "email", type: "email", placeholder: "Email" },
    { name: "phone", type: "text", placeholder: "Número de telefone" },
  ];

  return (
    <div className="contact-bg02">
      <div
        className="section-title wow fadeInDown animated"
        data-animation="fadeInDown"
        data-delay=".4s"
      >
        <h2>Fale Conosco</h2>
      </div>
      <form
        onSubmit={(e) =>
          handleSubmit(
            e,
            () => alert("Sucesso!"), // Success callback
            () => alert("Falha!") // Failure callback
          )
        }
        className="contact-form mt-30 wow fadeInUp animated"
        data-animation="fadeInUp"
        data-delay=".4s"
      >
        <div className="row">
          {formFields.map((field, index) => (
            <div className="col-lg-12" key={index}>
              <InputField
                name={field.name}
                value={formData[field.name as keyof FormData]}
                onChange={updateField}
                placeholder={field.placeholder}
              />
            </div>
          ))}
          <div className="col-lg-12">
            <TextAreaField
              name="message"
              value={formData.message}
              onChange={updateField}
              placeholder="Escreva sua pergunta"
            />
            <div className="slider-btn">
              <button className="btn ss-btn" type="submit">
                <span>Enviar</span> <i className="fal fa-long-arrow-right" />
              </button>
            </div>
            {status && <p>{status}</p>} {/* Exibe mensagens de status */}
          </div>
        </div>
      </form>
    </div>
  );
};

/**
 * FAQItem Component
 *
 * Renders an individual FAQ item.
 *
 * @param {FAQItem} item - The FAQ item object containing question and answer.
 * @param {number} index - The index for the accordion item.
 * @returns {JSX.Element} The FAQ item.
 */
const FAQItemComponent: React.FC<{ item: FAQItem; index: number }> = ({
  item,
  index,
}) => (
  <div className="card">
    <div className="card-header" id={`heading${index}`}>
      <h2 className="mb-0">
        <button
          className="faq-btn collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded="false"
          aria-controls={`collapse${index}`}
        >
          {item.question}
        </button>
      </h2>
    </div>
    <div
      id={`collapse${index}`}
      className="collapse"
      aria-labelledby={`heading${index}`}
      data-bs-parent="#accordionExample"
    >
      <div className="card-body">{item.answer}</div>
    </div>
  </div>
);

/**
 * FAQSection Component
 *
 * Renders the FAQ section with a list of frequently asked questions
 * and includes the contact form on the right-hand side.
 *
 * @returns {JSX.Element} The FAQ section.
 */
const FAQSection: React.FC = () => {
  // Array of FAQ items to be displayed in the accordion
  const faqItems: FAQItem[] = [
    {
      question: "Quem pode se beneficiar de uma consultoria de investimentos?",
      answer:
        "Qualquer pessoa ou empresa que deseja otimizar seus investimentos pode se beneficiar desse serviço. Isso inclui tanto investidores iniciantes, que precisam de orientação para começar, quanto investidores experientes, que buscam qualificar a forma como tomam decisões de investimentos.",
    },
    {
      question: "Posso cancelar o serviço de consultoria a qualquer momento?",
      answer:
        "Sim, você pode cancelar o serviço de consultoria a qualquer momento, sem penalidades. A Ostrya Investimentos oferece total flexibilidade para garantir que você esteja satisfeito com o serviço prestado.",
    },
    {
      question: "Há algum custo de cancelamento?",
      answer:
        "Não, não há custos de cancelamento. O cancelamento é simples e direto, sem taxas adicionais ou obrigações contratuais de longo prazo.",
    },
    {
      question: "O que acontece se eu já tiver um portfólio de investimentos?",
      answer:
        "Se você já possui um portfólio, o consultor fará uma avaliação detalhada para determinar se ele está alinhado com seu perfil de investidor e seus objetivos.",
    },
    {
      question: "Eu preciso seguir todas as recomendações do consultor?",
      answer:
        "Não, a decisão final sempre será sua. O consultor de investimentos fornece recomendações baseadas em uma análise cuidadosa do mercado e do seu perfil, mas você tem total controle sobre quais sugestões seguir e como implementá-las.",
    },
  ];

  return (
    <section className="faq-area pt-120 pb-120 p-relative fix">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7">
            <div
              className="section-title wow fadeInLeft animated"
              data-animation="fadeInDown animated"
              data-delay=".2s"
            >
              <h2>Perguntas Frequentes</h2>
              <p>
                Se você tiver perguntas adicionais ou precisar de mais
                esclarecimentos, nossa equipe está pronta para ajudar. Navegue
                pelas respostas abaixo para obter as informações que você
                precisa.
              </p>
            </div>
            <div className="faq-wrap mt-30 pr-30">
              <div className="accordion" id="accordionExample">
                {faqItems.map((item, index) => (
                  <FAQItemComponent key={index} item={item} index={index} />
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <ContactForm />{" "}
            {/* Formulário de contato embutido na seção de FAQ */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
