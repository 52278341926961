import React from "react";

/**
 * Props for the ListItem component.
 * Defines the expected parameters when rendering an individual list item.
 */
interface ListItemProps {
  icon: string;
  title: string;
  description: string;
}

/**
 * Component responsible for rendering an individual list item.
 *
 * @param icon - The icon to be displayed for the item.
 * @param title - The title of the item.
 * @param description - The description of the item.
 * @returns JSX.Element that represents a list item with an icon, title, and description.
 */
const ListItem: React.FC<ListItemProps> = ({ icon, title, description }) => {
  return (
    <li className="media d-flex align-items-start mb-4">
      <div className="mr-3 px-4">
        <i className={`fas ${icon} text-oc-secondary fa-2x`}></i>
      </div>
      <div className="media-body ml-3">
        <h5 className="mt-0 mb-1">{title}</h5>
        <span>{description}</span>
      </div>
    </li>
  );
};

/**
 * Data structure that holds the list items with icon, title, and description.
 */
const listItems = [
  {
    icon: "fa-exclamation-triangle",
    title: "Conflito de Interesses",
    description:
      "Nos grandes bancos, assessores ganham comissões por vender produtos específicos, o que pode não ser o melhor para você.",
  },
  {
    icon: "fa-eye-slash",
    title: "Falta de Transparência",
    description:
      "As taxas e comissões muitas vezes não são claras, e você pode estar pagando mais do que deveria.",
  },
  {
    icon: "fa-exchange-alt",
    title: "Troca Constante de Produtos",
    description:
      "Os bancos tradicionais e assessorias incentivam a troca frequente de produtos para gerar mais comissões.",
  },
  {
    icon: "fa-bullseye",
    title: "Pressão por Metas de Vendas",
    description:
      "Assessorias e bancos são pressionados a bater metas de vendas, o que pode comprometer as recomendações feitas.",
  },
  {
    icon: "fa-hand-holding-usd",
    title: "Foco em Produtos Próprios",
    description:
      "Bancos costumam priorizar produtos internos, que podem não ser as melhores opções para o seu perfil.",
  },
  {
    icon: "fa-clock",
    title: "Falta de Acompanhamento",
    description:
      "Depois da venda, o suporte oferecido pelos grandes bancos costuma ser mínimo, deixando você sem orientação.",
  },
];

/**
 * Component that represents the description section.
 * This component uses map to dynamically render list items in two columns.
 *
 * @returns JSX.Element that represents the description section.
 */
const DescriptionSection: React.FC = () => {
  // Split the list items into two columns
  const half = Math.ceil(listItems.length / 2);
  const firstColumnItems = listItems.slice(0, half);
  const secondColumnItems = listItems.slice(half);

  return (
    <div
      id="description"
      className="animated wow fadeInUp description bg-white py-5"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-left py-5 sect">
            <h2 className="text-center display-6 mb-5">
              Os Erros Comuns dos Bancos e Assessorias que <br /> Podem Custar
              Caro
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ul className="list-unstyled">
              {firstColumnItems.map((item, index) => (
                <ListItem
                  key={index}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </ul>
          </div>
          <div className="col-lg-6">
            <ul className="list-unstyled">
              {secondColumnItems.map((item, index) => (
                <ListItem
                  key={index}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Main component that renders the DescriptionSection.
 *
 * @returns JSX.Element that represents the entire description page.
 */
const DescriptionPage: React.FC = () => {
  return <DescriptionSection />;
};

export default DescriptionPage;
