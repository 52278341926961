import { useState, useEffect } from "react";

/**
 * Custom hook to manage the header state, including mobile view and scroll detection.
 *
 * @returns An object containing the mobile state, setMobile function, and isScrolled state.
 */
const useHeaderState = () => {
  const [mobile, setMobile] = useState<boolean>(false); // 'mobile' is a boolean, not iterable
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    mobile,
    setMobile, // This is the setter function for the 'mobile' state, not iterable
    isScrolled,
  };
};

export default useHeaderState;
