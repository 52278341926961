import { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";

import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
import Importance from "../components/Importance/Importance";
import Methodology from "../components/Methodology/Methodology";
import MultiFamily from "../components/MultiFamily/MultiFamily";
import Problems from "../components/Problems/Problems";
import Evaluation from "../components/Evaluation/Evaluation";
import Planning from "../components/Planning/Planning";
import Consulting from "../components/Insights/Insights";
import Frequently from "../components/Frequently/Frequently";
import Mentoring from "../components/Mentoring/Mentoring";
import Schedule from "../components/Schedule/Schedule";
import Newsletter from "../components/Newsletter/Newsletter";
import NewsletterModal from "../components/NewsletterModal/NewsletterModal";

function Home() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const modalShown = sessionStorage.getItem("modalShown");
    if (!modalShown) {
      openModal();
      sessionStorage.setItem("modalShown", "true");
    }
  }, []);

  const sections = [
    { component: <Hero />, key: "hero" },
    { component: <About />, key: "about" },
    { component: <WhyChooseUs />, key: "whyChooseUs" },
    { component: <Importance />, key: "importance" },
    { component: <Methodology />, key: "methodology" },
    { component: <MultiFamily />, key: "multiFamily" },
    { component: <Problems />, key: "problems" },
    { component: <Evaluation />, key: "evaluation" },
    { component: <Mentoring />, key: "mentoring" },
    { component: <Planning />, key: "planning" },
    { component: <Consulting />, key: "consulting" },
    { component: <Frequently />, key: "frequently" },
    { component: <Schedule />, key: "schedule" },
    { component: <Newsletter />, key: "newsletter" },
  ];

  return (
    <>
      {sections.map(({ component, key }) => (
        <InView key={key} triggerOnce={true} threshold={0.1}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              id={`${key}-section`}
              className={`fade-in-section ${inView ? "is-visible" : ""}`}
            >
              {component}
            </div>
          )}
        </InView>
      ))}

      <NewsletterModal
        isOpen={isModalOpen}
        onClose={closeModal}
        submitUrl="https://enchanting-ambition-production.up.railway.app/send"
      />
    </>
  );
}

export default Home;
