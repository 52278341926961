export interface ContactInfo {
  icon: string;
  label: string;
  to: string;
  text: string;
}

export const contactInfo: ContactInfo[] = [
  {
    icon: "fal fa-phone",
    label: "Fale conosco",
    to: "tel:+5551981646071",
    text: "+55 51 98164-6071",
  },
  {
    icon: "fal fa-envelope",
    label: "Email",
    to: "mailto:investimentos@ostrya.com.br",
    text: "investimentos@ostrya.com.br",
  },
];