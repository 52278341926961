import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes";

// CSS Imports
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/fontawesome/css/all.min.css";
import "./assets/css/dripicons.css";
import "./assets/css/slick.css";
import "./assets/css/meanmenu.css";
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

const Router: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Routing />
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  return <Router />;
};

export default App;
