import React from "react";

/**
 * Component responsible for rendering the section title.
 *
 * @returns JSX.Element that represents the section title and description.
 */
const SectionTitle: React.FC = () => {
  return (
    <div
      className="section-title wow fadeInLeft animated"
      data-animation="fadeInDown animated"
      data-delay=".2s"
    >
      <h2>Agende uma reunião com nossos especialistas</h2>
      <p className="py-4">
        Garanta o futuro do seu patrimônio e o crescimento dos seus
        investimentos com o suporte de nossos consultores especializados. Em uma
        reunião personalizada, vamos avaliar suas necessidades, discutir as
        melhores estratégias de investimento e apresentar um planejamento
        sucessório eficaz e alinhado aos seus objetivos.
      </p>
    </div>
  );
};

/**
 * Component responsible for rendering the CTA button.
 *
 * @returns JSX.Element that represents the call-to-action button.
 */
const CtaButton: React.FC = () => {
  return (
    <div
      className="cta-btn s-cta-btn wow fadeInRight animated"
      data-animation="fadeInDown animated"
    >
      <a
        href="https://wa.me/5551981646071?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20consultoria%20de%20investimentos"
        target="_blank"
        rel="noopener noreferrer"
        className="btn ss-btn smoth-scroll"
      >
        AGENDE AGORA <i className="fal fa-long-arrow-right"></i>
      </a>
    </div>
  );
};

/**
 * Component responsible for rendering the CTA section.
 *
 * This component combines the section title and CTA button, organizing them
 * within a responsive layout.
 *
 * @returns JSX.Element that represents the call-to-action area.
 */
const CtaArea: React.FC = () => {
  return (
    <section className="bg-sky cta-area pt-50 pb-50">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8">
            <SectionTitle />
          </div>
          <div className="col-lg-4 text-right">
            <CtaButton />
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * Main component that renders the CtaArea.
 *
 * @returns JSX.Element that represents the entire CTA section with title and button.
 */
const Schedule: React.FC = () => {
  return <CtaArea />;
};

export default Schedule;
