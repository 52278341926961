import { useState, ChangeEvent, FormEvent } from "react";
import axios from "axios";

/**
 * Type definition for the form data object
 */
interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

/**
 * Custom hook to manage form data.
 *
 * @param initialState - The initial state of the form data.
 * @returns An object containing the form data, updateField function, and resetForm function.
 */
const useForm = (initialState: ContactFormData) => {
  const [formData, setFormData] = useState<ContactFormData>(initialState);

  const updateField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => setFormData(initialState);

  return {
    formData,
    updateField,
    resetForm,
  };
};

/**
 * Checks if the form data is valid by ensuring no fields are empty.
 *
 * @param formData - The form data object.
 * @returns True if all fields are filled, otherwise false.
 */
const isFormValid = (formData: ContactFormData): boolean => {
  return Object.values(formData).every((value) => value.trim() !== "");
};

/**
 * Submits the form data to the backend.
 *
 * @param formData - The form data object.
 * @returns The response data from the server.
 */
const submitForm = async (formData: ContactFormData) => {
  const response = await axios.post(
    "https://enchanting-ambition-production.up.railway.app/contact",
    formData
  );

  return response.data;
};

/**
 * Custom hook to handle contact form submission.
 *
 * @param initialState - The initial state of the form data.
 * @returns An object containing form data, status message, updateField function, and handleSubmit function.
 */
const useContactForm = (initialState: ContactFormData) => {
  const { formData, updateField, resetForm } = useForm(initialState);
  const [status, setStatus] = useState<string>("");

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    e.preventDefault();

    if (!isFormValid(formData)) {
      setStatus("Por favor, preencha todos os campos.");
      return;
    }

    try {
      const response = await submitForm(formData);
      if (!response.success) {
        setStatus("Ocorreu um erro ao enviar o formulário.");
        onError?.();
        return;
      }

      resetForm();
      setStatus("Mensagem enviada com sucesso!");
      onSuccess?.();
    } catch (err) {
      console.error("Erro ao enviar o formulário:", err);
      setStatus("Erro ao enviar a mensagem. Tente novamente mais tarde.");
      onError?.();
    }
  };

  return {
    formData,
    status,
    updateField,
    handleSubmit,
  };
};

export default useContactForm;
