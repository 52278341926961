import React from "react";
import Button from "../commons/Button";
import { EVALUATION_IMAGE } from "../../constants/images/images.c";
import { WHATSAPP_LINK } from "../../constants/links/links.c";

/**
 * Component responsible for rendering the header section of the evaluation.
 * This includes the title, description, and a button to schedule a free portfolio evaluation.
 *
 * @returns JSX.Element that represents the evaluation header.
 */
const EvaluationHeader: React.FC = () => {
  return (
    <div className="p-5">
      <h6 className="section-title text-start text-white text-uppercase mb-3">
        Avaliação Gratuita
      </h6>
      <h1 className="text-white mb-4">
        Solicite uma avaliação gratuita do seu portfólio
      </h1>
      <p className="text-white mb-4">
        A Ostrya Investimentos oferece uma avaliação gratuita do seu portfólio,
        ajudando você a aproveitar as melhores oportunidades para melhorar seus
        resultados financeiros.
      </p>
      <Button href={WHATSAPP_LINK} buttonText="Agende Agora Sua Avaliação" />
    </div>
  );
};

/**
 * Component responsible for rendering the evaluation image.
 *
 * @returns JSX.Element that represents the image associated with the evaluation section.
 */
const EvaluationImage: React.FC = () => {
  return (
    <div className="image-container">
      <img
        src={EVALUATION_IMAGE}
        alt="Avaliação Gratuita"
        className="img-fluid"
      />
    </div>
  );
};

/**
 * Main component that combines the header and image for the evaluation section.
 * This component is split into two columns, one for the header and one for the image.
 *
 * @returns JSX.Element that represents the entire evaluation section.
 */
const Evaluation: React.FC = () => {
  return (
    <div className="container-xxl px-0 my-5 wow zoomIn" data-wow-delay="0.1s">
      <div className="row g-0">
        <div className="col-md-6 bg-oc-primary d-flex align-items-center">
          <EvaluationHeader />
        </div>
        <div className="col-md-6">
          <EvaluationImage />
        </div>
      </div>
    </div>
  );
};

export default Evaluation;
