import React from "react";
import Button from "../commons/Button";
import useNewsletterForm from "../../hooks/useNewsletterForm";

// Interface for input field props
interface InputFieldProps {
  id: string;
  placeholder: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * NameInputField Component
 *
 * This component renders an input field for the user's name.
 * It capitalizes each word as the user types.
 *
 * @param {InputFieldProps} props - Contains id, placeholder, label, value, and onChange handler.
 * @returns {JSX.Element} - The JSX for the name input field.
 */
const NameInputField: React.FC<InputFieldProps> = ({
  id,
  placeholder,
  label,
  value,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/\b\w/g, (c) => c.toUpperCase());
    onChange({
      target: { id, value: formattedValue },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="col-12 col-md-6 mb-4">
      <div className="form-floating">
        <input
          type="text"
          className="form-control"
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};

/**
 * EmailInputField Component
 *
 * This component renders an input field for the user's email.
 * It converts the input value to lowercase as the user types.
 *
 * @param {InputFieldProps} props - Contains id, placeholder, label, value, and onChange handler.
 * @returns {JSX.Element} - The JSX for the email input field.
 */
const EmailInputField: React.FC<InputFieldProps> = ({
  id,
  placeholder,
  label,
  value,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    onChange({
      target: { id, value: inputValue },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="col-12 col-md-6 mb-4">
      <div className="form-floating">
        <input
          type="email"
          className="form-control"
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};

/**
 * NumericInputField Component
 *
 * This component renders an input field for the user's phone number.
 * It formats the phone number as the user types.
 *
 * @param {InputFieldProps} props - Contains id, placeholder, label, value, and onChange handler.
 * @returns {JSX.Element} - The JSX for the numeric input field.
 */
const NumericInputField: React.FC<InputFieldProps> = ({
  id,
  placeholder,
  label,
  value,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = "";

    if (inputValue.length <= 10) {
      formattedValue = inputValue.replace(
        /^(\d{2})(\d{4})(\d{0,4}).*/,
        "($1) $2-$3"
      );
    } else {
      formattedValue = inputValue.replace(
        /^(\d{2})(\d{5})(\d{0,4}).*/,
        "($1) $2-$3"
      );
    }

    onChange({
      target: { id, value: formattedValue },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="col-12 col-md-6 mb-4">
      <div className="form-floating">
        <input
          type="tel"
          className="form-control"
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};

/**
 * CurrencyInputField Component
 *
 * This component renders an input field for the user's investment value.
 * It formats the input as a currency in BRL (Brazilian Real) as the user types.
 *
 * @param {InputFieldProps} props - Contains id, placeholder, label, value, and onChange handler.
 * @returns {JSX.Element} - The JSX for the currency input field.
 */
const CurrencyInputField: React.FC<InputFieldProps> = ({
  id,
  placeholder,
  label,
  value,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let numberValue = parseFloat(inputValue) / 100;

    if (isNaN(numberValue)) {
      numberValue = 0;
    }

    const formattedValue = numberValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });

    onChange({
      target: { id, value: formattedValue },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="col-12 col-md-6 mb-4">
      <div className="form-floating">
        <input
          type="text"
          className="form-control"
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};

/**
 * NewsletterForm Component
 *
 * This component renders the newsletter subscription form using the `useNewsletterForm` hook.
 * The form includes fields for name, phone, email, and investment value.
 *
 * @returns {JSX.Element} - The JSX for the newsletter form.
 */
const NewsletterForm: React.FC = () => {
  const { formData, updateField, handleSubmit, error } = useNewsletterForm({
    floatingName: "",
    floatingPhone: "",
    floatingValue: "",
    floatingInput: "",
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="row g-4">
          <NameInputField
            id="floatingName"
            placeholder="Nome"
            label="Nome"
            value={formData.floatingName}
            onChange={updateField}
          />
          <NumericInputField
            id="floatingPhone"
            placeholder="Telefone"
            label="Telefone"
            value={formData.floatingPhone}
            onChange={updateField}
          />
          <CurrencyInputField
            id="floatingValue"
            placeholder="Valor Aplicado"
            label="Valor Aplicado"
            value={formData.floatingValue}
            onChange={updateField}
          />
          <EmailInputField
            id="floatingInput"
            placeholder="name@example.com"
            label="Endereço de email"
            value={formData.floatingInput}
            onChange={updateField}
          />
          {error && <p className="text-danger">{error}</p>}
          <div className="col-12 text-center mt-3">
            <Button
              href=""
              component="button"
              type="submit"
              buttonText="INSCREVA-SE"
              buttonClassName="btn "
            />
          </div>
        </div>
      </div>
    </form>
  );
};

/**
 * NewsletterSection Component
 *
 * This component renders the section for the newsletter subscription.
 * It includes the form and a description.
 *
 * @param {string} submitUrl - The URL to which the form will be submitted.
 * @returns {JSX.Element} - The JSX for the newsletter section.
 */
const NewsletterSection: React.FC<{ submitUrl: string }> = ({ submitUrl }) => {
  return (
    <div className="animated fadeInUp">
      <div className="container my-5" data-aos="fade" data-aos-delay="200">
        <div className="row justify-content-center align-items-center">
          <div className="section-title text-center wow fadeInLeft animated">
            <h2>
              Inscreva-se na nossa{" "}
              <span className="fw-bold text-oc-secondary">Newsletter</span> e
              receba atualizações essenciais sobre o mercado financeiro.
            </h2>
            <p className="py-4">
              Receba atualizações essenciais sobre o mercado financeiro e as
              melhores estratégias de investimento para garantir o futuro do seu
              patrimônio.
            </p>
          </div>
          <div className="">
            <NewsletterForm />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * NewsletterPage Component
 *
 * This is the main page component that renders the newsletter section.
 *
 * @returns {JSX.Element} - The JSX for the newsletter page.
 */
const NewsletterPage: React.FC = () => {
  return <NewsletterSection submitUrl="/newsletter" />;
};

export default NewsletterPage;
