import React from "react";
import { WHY_CHOOSE_US_IMAGE } from "../../constants/images/images.c";

/**
 * Main component that combines the text and image content
 * of the "Why Choose Us" section.
 *
 * @returns JSX.Element that represents the section.
 */
const WhyChooseUs: React.FC = () => {
  return (
    <div className="animated fadeInUp container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <TextContent />
          <ImageContent />
        </div>
      </div>
    </div>
  );
};

/**
 * Component responsible for rendering the text content of the "Why Choose Us" section.
 *
 * @returns JSX.Element that represents the text content.
 */
const TextContent: React.FC = () => {
  return (
    <div className="col-lg-6 wow fadeInUp">
      <div className="border-start border-5 border-oc-primary ps-4 mb-5">
        <h6 className="text-body text-uppercase mb-2">POR QUE NOS ESCOLHER?</h6>
        <h2 className="display-6 mb-0">Soluções Financeiras Sob Medida</h2>
      </div>
      <p className="mb-5">
        Consultoria independente, transparência total e atendimento
        personalizado para o crescimento seguro do&nbsp;seu&nbsp;patrimônio.
      </p>
      <div className="row gy-5 gx-4">
        {featuresData.map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>
    </div>
  );
};

/**
 * Interface that defines the type for each feature's props.
 */
interface FeatureProps {
  title: string;
  description: string;
}

/**
 * Component responsible for rendering each individual feature.
 *
 * @param title - The title of the feature.
 * @param description - The description of the feature.
 * @returns JSX.Element that represents a feature.
 */
const Feature: React.FC<FeatureProps> = ({ title, description }) => {
  return (
    <div className="col-sm-6 wow fadeIn">
      <div className="d-flex align-items-center mb-3">
        <i className="fa fa-check fa-2x text-oc-secondary flex-shrink-0 me-3"></i>
        <h6 className="mb-0">{title}</h6>
      </div>
      <span>{description}</span>
    </div>
  );
};

/**
 * Data array for the feature items to be displayed in the "Why Choose Us" section.
 */
const featuresData = [
  {
    title: "Consultoria Independente",
    description:
      "Atuamos sem vínculo com corretoras ou instituições financeiras, garantindo recomendações imparciais focadas apenas nos seus interesses.",
  },
  {
    title: "Atendimento Personalizado",
    description:
      "Oferecemos acompanhamento contínuo e ajustamos seu portfólio conforme suas metas e perfil, garantindo resultados alinhados às suas expectativas.",
  },
  {
    title: "Remuneração Fee Based",
    description:
      "Nosso modelo fee based assegura que nossos interesses estejam sempre alinhados aos seus, com total transparência e sem comissões ocultas.",
  },
  {
    title: "Rebate e Benefícios",
    description:
      "Trabalhamos com corretoras parceiras que oferecem cashback aos nossos clientes, maximizando seus ganhos sem comprometer nossa&nbsp;independência.",
  },
];

/**
 * Component responsible for rendering the image content of the "Why Choose Us" section.
 *
 * @returns JSX.Element that represents the image content.
 */
const ImageContent: React.FC = () => {
  return (
    <div className="col-lg-6 wow fadeInUp">
      <div
        className="position-relative overflow-hidden ps-5 pt-5 h-100"
        style={{ minHeight: "400px" }}
      >
        <img
          className="position-absolute w-100 h-100"
          src={WHY_CHOOSE_US_IMAGE}
          alt="Why Choose Us"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;
