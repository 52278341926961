import React from "react";
import { INSIGHT_CARD_04 } from "../../../constants/images/images.c";

const UpperBox = () => {
  return (
    <div className="upper-box">
      <div className="single-item-carousel owl-carousel owl-theme">
        <figure className="image">
          <img src={INSIGHT_CARD_04} alt="Investimentos" />
        </figure>
      </div>
    </div>
  );
};

const LowerContent = () => {
  return (
    <div className="lower-content2">
      <div className="row">
        <div className="text-column col-lg-9 col-md-12 col-sm-12">
          <div
            className="s-about-content wow fadeInRight"
            data-animation="fadeInRight"
            data-delay=".2s"
          >
            <h2>
              Aposentadoria: Como R$ 1.000 Mensais Podem Render Mais de R$ 1
              Milhão
            </h2>
            <p>
              Não é segredo nenhum que a previdência social enfrenta muitos
              desafios em relação à sua sustentabilidade, tendo encerrado 2023
              com um <strong>déficit de R$ 306,0 bilhões</strong>, segundo o
              Governo Federal. Assim, torna-se cada vez mais importante que as
              famílias brasileiras planejem a sua aposentadoria sem depender da
              previdência social. Contudo, segundo a Fenaprevi,{" "}
              <strong>
                apenas 7,0% da população brasileira possui plano de previdência
                privada em 2024.
              </strong>
            </p>
            <p>
              A formação de patrimônio financeiro para que seja utilizado no
              futuro como fonte de renda, seja única, principal ou complementar,
              depende de fatores como <strong>disciplina</strong>,{" "}
              <strong>tempo</strong> e <strong>decisões adequadas</strong>. A{" "}
              <strong>disciplina</strong> de reservar parte da renda de forma
              recorrente para as aplicações financeiras é fundamental para
              acelerar o processo de acumulação de capital. Conforme será
              apresentado mais adiante, pequenos valores poupados mensalmente e
              aplicados a taxas de retorno adequadas, podem representar somas
              representativas no futuro.
            </p>
            <p>
              <strong>Vamos à algumas simulações.</strong> <br />
              Considerando uma taxa de juros real, ou seja, acima da inflação,
              já líquida de imposto de renda, de 5,30% a.a., que hoje é possível
              obter com títulos públicos indexados ao IPCA, um investimento
              mensal de R$ 1.000,00, somaria:
            </p>
            <p>R$ 156,7 mil em 10 anos</p>
            <p>R$ 419,5 mil em 20 anos</p>
            <p>R$ 859,8 mil em 30 anos</p>
            <p>
              Lembrando que estou falando em termos reais, ou seja, acima da
              inflação do período.
            </p>
            <p>
              Agora, caso o investidor tenha algum apetite por risco e faça uma
              alocação, de parte da carteira, em risco de crédito, obtendo,
              digamos, 6,50% a.a. de retorno no seu portfólio como um todo, o
              mesmo aporte mensal de R$ 1.000,00 somaria:
            </p>
            <p>R$ 166,7 mil em 10 anos</p>
            <p>R$ 479,6 mil em 20 anos</p>
            <p>R$ 1,067 milhão em 30 anos</p>
            <p>
              Assim, assumindo-se algum tipo de risco, é possível acumular pouco
              mais de R$ 1,0 milhão, com aportes de R$ 1,0 mil/mês ao longo de
              30 anos.
            </p>
            <p>
              Agora, para investidores que aceitam assumir alguma volatilidade
              maior, alocando parte dos recursos em renda variável, pode-se
              esperar um retorno real maior, digamos de 8,0% a.a., assim, R$
              1.000,00 mensais alcançariam:
            </p>
            <p>R$ 180,1 mil em 10 anos</p>
            <p>R$ 569,0 mil em 20 anos</p>
            <p>R$ 1,409 milhão em 30 anos</p>
            <p>
              Como dito anteriormente, a <strong>disciplina</strong>, o{" "}
              <strong>tempo</strong> e as <strong>decisões adequadas</strong>
              sobre a alocação, são fundamentais para o sucesso na construção de
              riqueza ao longo do tempo.
            </p>
            <p>
              Para auxiliar o investidor neste processo é fundamental o apoio de
              profissionais tecnicamente capacitados, experientes e que tenham
              os interesses alinhados com os seus clientes. Neste sentido, a{" "}
              <strong>Ostrya Investimentos</strong>, uma consultoria
              independente, conta com soluções financeiras completas para todos
              os perfis de clientes e é focada na criação de riqueza e
              relacionamento de longo prazo com os investidores. Entre em
              contato com um consultor da <strong>Ostrya Investimentos</strong>e
              venha saber como podemos lhe ajudar a construir riqueza ao longo
              do tempo através de investimentos no mercado financeiro.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Fourth = () => {
  return (
    <section className="project-detail">
      <div className="container">
        <UpperBox />
        <LowerContent />
      </div>
    </section>
  );
};

export default Fourth;
