import React from "react";
import {
  ABOUT_FLOAT_HORIZONTAL_ICON,
  INSIGHT_CARD_01,
  INSIGHT_CARD_02,
  INSIGHT_CARD_03,
  INSIGHT_CARD_04,
  INSIGHT_CARD_05,
} from "../../constants/images/images.c";

// Interface for EventItem props
interface EventItemProps {
  href: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
}

/**
 * EventItem Component
 *
 * Renders an individual event item with an image, title, description, and a link to more details.
 *
 * @param {EventItemProps} props - Contains href, imgSrc, imgAlt, title, and description for the event item.
 * @returns {JSX.Element} - The JSX for an individual event item.
 */
const EventItem: React.FC<EventItemProps> = ({
  href,
  imgSrc,
  imgAlt,
  title,
  description,
}) => {
  return (
    <div
      className="col-lg-4 col-md-6 wow fadeInUp animated"
      data-animation="fadeInUp"
      data-delay=".4s"
    >
      <div className="event-item mb-30 hover-zoomin">
        <div className="thumb">
          <a href={href}>
            <img src={imgSrc} alt={imgAlt} />
          </a>
        </div>
        <div className="event-content">
          <h3>
            <a href={href}>{title}</a>
          </h3>
          <p>{description}</p>
          <div className="time">
            <a href={href}>
              <i className="fal fa-long-arrow-right"></i>{" "}
              <strong>Saiba mais</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

// Array of event data
const eventData: EventItemProps[] = [
  {
    href: "/insights/diferenca-de-consultoria-e-assessoria",
    imgSrc: INSIGHT_CARD_01,
    imgAlt: "Consultoria x Assessoria: Entenda a Diferença",
    title: "Consultoria x Assessoria: Entenda a Diferença",
    description:
      "Conheça as principais diferenças entre consultoria e assessoria de investimentos.",
  },
  {
    href: "/insights/transparencia-e-alinhamento-entenda-a-remuneracao",
    imgSrc: INSIGHT_CARD_02,
    imgAlt: "Transparência e Alinhamento: Entenda a Remuneração",
    title: "Transparência e Alinhamento: Entenda a Remuneração",
    description:
      "Como funciona a remuneração de um consultor de investimentos.",
  },
  {
    href: "/insights/aposentadoria-como-rs-1000-mensais-podem-render-mais-de-1-milhao",
    imgSrc: INSIGHT_CARD_03,
    imgAlt: "Aposentadoria: Investindo para o futuro",
    title: "Aposentadoria: Investindo para o futuro",
    description:
      "Entenda como R$ 1.000 Mensais Podem Render Mais de R$ 1 Milhão",
  },
  {
    href: "/insights/acoes-como-se-tornar-socio",
    imgSrc: INSIGHT_CARD_04,
    imgAlt: "Ações: Como Se Tornar Sócio das Maiores Empresas do Brasil",
    title: "Ações: Como Se Tornar Sócio das Maiores Empresas do Brasil",
    description:
      "Você gostaria de ser sócio das melhores empresas brasileiras? Entenda como isso é possível.",
  },
  {
    href: "/insights/renda-fixa-aproveitar-juros-altos",
    imgSrc: INSIGHT_CARD_05,
    imgAlt: "Como Aproveitar Juros Altos e Proteger Seu Capital",
    title: "Como Aproveitar Juros Altos e Proteger Seu Capital",
    description:
      "Descubra como a renda fixa pode oferecer estabilidade e rentabilidade.",
  },
];

/**
 * EventSection Component
 *
 * This component renders the entire section containing the event items. The event items are
 * dynamically rendered using the `map` function from the `eventData` array.
 *
 * @returns {JSX.Element} - The JSX for the event section with dynamically rendered event items.
 */
const EventSection: React.FC = () => {
  return (
    <section id="investments" className="event pt-120 pb-90 p-relative fix">
      <div className="animations-07">
        <img src={ABOUT_FLOAT_HORIZONTAL_ICON} alt="contact-bg-an-01" />
      </div>
      <div className="animations-09">
        <img src={ABOUT_FLOAT_HORIZONTAL_ICON} alt="contact-bg-an-01" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-relative">
            <div
              className="section-title center-align mb-50 text-center wow fadeInDown animated"
              data-animation="fadeInDown"
              data-delay=".4s"
            >
              <h5>Insights de Investimento</h5>
            </div>
          </div>
        </div>
        <div className="row">
          {eventData.map((event, index) => (
            <EventItem
              key={index}
              href={event.href}
              imgSrc={event.imgSrc}
              imgAlt={event.imgAlt}
              title={event.title}
              description={event.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

/**
 * Insights Component
 *
 * This is the main component that renders the EventSection, which contains all the insight events.
 *
 * @returns {JSX.Element} - The JSX for the insights section.
 */
const Insights: React.FC = () => {
  return <EventSection />;
};

export default Insights;
