import React from "react";
import useNewsletterForm from "../../hooks/useNewsletterForm";

// Interface for the props of reusable components
interface ModalProps {
  children: React.ReactNode;
}

interface InputFieldProps {
  id: string;
  placeholder: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  handleFormatting?: (value: string) => string;
}

interface ButtonProps {
  buttonText: string;
}

interface NewsletterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface CloseButtonProps {
  onClick: () => void;
}

/**
 * ModalOverlay Component
 *
 * This component renders the modal's overlay with a semi-transparent background.
 * It is used as the backdrop for the modal.
 *
 * @param {ModalProps} props - Contains the children components to display inside the overlay.
 * @returns {JSX.Element} - The JSX for the modal overlay.
 */
const ModalOverlay: React.FC<ModalProps> = ({ children }) => (
  <div
    className="modal-overlay"
    style={{
      position: "fixed",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      backdropFilter: "blur(8px)",
      overflowY: "auto",
    }}
  >
    {children}
  </div>
);

/**
 * ModalContent Component
 *
 * This component renders the content inside the modal. It includes styling for the modal's body.
 *
 * @param {ModalProps} props - Contains the children components to display inside the modal content.
 * @returns {JSX.Element} - The JSX for the modal content.
 */
const ModalContent: React.FC<ModalProps> = ({ children }) => (
  <div
    className="modal-content bg-white p-4"
    style={{
      maxWidth: "600px",
      width: "95%",
      minHeight: "600px",
      maxHeight: "90vh",
      borderRadius: "8px",
      overflowY: "auto",
      position: "relative",
    }}
  >
    {children}
  </div>
);

/**
 * CloseButton Component
 *
 * Renders the close button for the modal. It is positioned in the top right corner of the modal.
 *
 * @param {CloseButtonProps} props - Contains the onClick event to close the modal.
 * @returns {JSX.Element} - The JSX for the close button.
 */
const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => (
  <button
    style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      fontSize: "1.5rem",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    ×
  </button>
);

/**
 * InputField Component
 *
 * This is a reusable component for rendering input fields such as text, phone, email, and currency fields.
 * It handles the formatting of input values based on the provided formatting function.
 *
 * @param {InputFieldProps} props - Contains the properties for the input field.
 * @returns {JSX.Element} - The JSX for the input field.
 */
const InputField: React.FC<InputFieldProps> = ({
  id,
  placeholder,
  label,
  value,
  onChange,
  type,
  handleFormatting,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (handleFormatting) {
      inputValue = handleFormatting(inputValue);
    }
    onChange({
      target: { id, value: inputValue },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="col-12 mb-3">
      <div className="form-floating">
        <input
          type={type}
          className="form-control p-3"
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <label htmlFor={id} className="py-2">
          {label}
        </label>
      </div>
    </div>
  );
};

/**
 * Button Component
 *
 * Renders a button for form submission.
 *
 * @param {ButtonProps} props - Contains the text for the button.
 * @returns {JSX.Element} - The JSX for the button.
 */
const Button: React.FC<ButtonProps> = ({ buttonText }) => (
  <button type="submit" className="btn btn-dark w-100 py-3 fw-bold">
    {buttonText}
  </button>
);

/**
 * NewsletterForm Component
 *
 * This component renders the newsletter subscription form using the `useNewsletterForm` hook.
 * It includes dynamic form fields for name, phone, applied value, and email, with custom formatting.
 *
 * @param {CloseButtonProps} props - Contains the onClose event to close the modal after form submission.
 * @returns {JSX.Element} - The JSX for the newsletter form.
 */
const NewsletterForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { formData, updateField, handleSubmit, error } = useNewsletterForm({
    floatingName: "",
    floatingPhone: "",
    floatingValue: "",
    floatingInput: "",
  });

  const formFields = [
    {
      id: "floatingName",
      placeholder: "Nome",
      label: "Nome",
      type: "text",
      handleFormatting: (value: string) =>
        value.replace(/\b\w/g, (c) => c.toUpperCase()), // Capitalize each word
    },
    {
      id: "floatingPhone",
      placeholder: "Telefone",
      label: "Telefone",
      type: "tel",
      handleFormatting: (value: string) => {
        const cleaned = value.replace(/\D/g, "");
        if (cleaned.length <= 10) {
          return cleaned.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
        }
        return cleaned.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
      },
    },
    {
      id: "floatingValue",
      placeholder: "Valor Aplicado",
      label: "Valor Aplicado",
      type: "text",
      handleFormatting: (value: string) => {
        const cleaned = value.replace(/\D/g, "");
        const numberValue = parseFloat(cleaned) / 100;
        if (isNaN(numberValue)) return "";
        return numberValue.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
        });
      },
    },
    {
      id: "floatingInput",
      placeholder: "name@example.com",
      label: "Endereço de email",
      type: "email",
      handleFormatting: (value: string) => value.toLowerCase(),
    },
  ];

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmit(e, () => {
      onClose();
    });
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="grouped-inputs border bg-light p-3"
    >
      <div className="row">
        {formFields.map((field) => (
          <InputField
            key={field.id}
            id={field.id}
            placeholder={field.placeholder}
            label={field.label}
            type={field.type}
            value={formData[field.id]}
            onChange={updateField}
            handleFormatting={field.handleFormatting}
          />
        ))}
        <div className="col-12 col-md-4">
          <Button buttonText="INSCREVA-SE" />
        </div>
      </div>
      {error && <p className="text-danger">{error}</p>}
    </form>
  );
};

/**
 * NewsletterModal Component
 *
 * Renders the modal for the newsletter subscription, including the form and close button.
 * The modal is conditionally rendered based on the `isOpen` prop.
 *
 * @param {NewsletterModalProps} props - Contains the isOpen state and onClose event to control modal visibility.
 * @returns {JSX.Element | null} - The JSX for the newsletter modal, or null if the modal is closed.
 */
const NewsletterModal: React.FC<NewsletterModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose} />
        <h2 className="fs-2 fw-light mb-4">
          Inscreva-se na nossa{" "}
          <span className="fw-bold text-oc-secondary">Newsletter</span> e receba
          atualizações essenciais sobre o mercado financeiro.
        </h2>
        <NewsletterForm onClose={onClose} />
      </ModalContent>
    </ModalOverlay>
  );
};

export default NewsletterModal;
