import React from "react";
import { useParams } from "react-router-dom";

import First from "../insights-content/First";
import Second from "../insights-content/Second";
import Third from "../insights-content/Third";
import Fourth from "../insights-content/Fourth";
import Fifth from "../insights-content/Fifith";

function InsightDetails() {
  const { insightId } = useParams();

  console.log(insightId);

  const renderInsightDetails = () => {
    switch (insightId) {
      case "diferenca-de-consultoria-e-assessoria":
        return <First />;
      case "transparencia-e-alinhamento-entenda-a-remuneracao":
        return <Second />;
      case "aposentadoria-como-rs-1000-mensais-podem-render-mais-de-1-milhao":
        return <Third />;
      case "acoes-como-se-tornar-socio":
        return <Fourth />;
      case "renda-fixa-aproveitar-juros-altos":
        return <Fifth />;
      default:
        return;
    }
  };

  return <>{renderInsightDetails()}</>;
}

export default InsightDetails;
