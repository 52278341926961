import React from "react";
import { Link } from "react-router-dom";

// Hooks
import useHeaderState from "../../hooks/useHeaderState";

// Links (constants for social media and contact)
import { WHATSAPP_LINK } from "../../constants/links/links.c";

// Images (constant for logo image)
import { LOGO_IMAGE } from "../../constants/images/images.c";

// Data (contact information, social media links and navigation items)
import { contactInfo } from "../../constants/data/contactInfo";
import { socialLinks } from "../../constants/data/socialLinks";
import { navigationItems } from "../../constants/data/navigationItems";

// Customized components (importing reusable button component)
import Button from "../commons/Button";

/**
 * HeaderCTA Component
 *
 * Renders the contact information section of the header.
 * This includes contact details such as phone number and email.
 *
 * @returns {JSX.Element} - The JSX for the contact info section in the header.
 */
const HeaderCTA: React.FC = () => (
  <div className="header-cta">
    <ul>
      {contactInfo.map((contact, index) => (
        <li key={index}>
          <div className="call-box">
            <div className="icon">
              <i className={contact.icon}></i>
            </div>
            <div className="text">
              <span>{contact.label}</span>
              <strong>
                <a href={contact.to}>{contact.text}</a>
              </strong>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

/**
 * HeaderSocialLinks Component
 *
 * Renders the social media links section of the header.
 * This includes icons for Instagram, LinkedIn, and WhatsApp.
 *
 * @returns {JSX.Element} - The JSX for social media icons in the header.
 */
const HeaderSocialLinks: React.FC = () => (
  <div className="header-social">
    {socialLinks.map((link, index) => (
      <Link to={link.href} title={link.title} target="_blank" key={index}>
        <i className={link.iconClass} />
      </Link>
    ))}
  </div>
);

// Interface for StickyWrapper component props
interface StickyWrapperProps {
  isScrolled: boolean;
  children: React.ReactNode;
}

/**
 * StickyWrapper Component
 *
 * StickyWrapper wraps around the menu area and adds the 'sticky-menu' class
 * when the page is scrolled down.
 *
 * @param {StickyWrapperProps} props - The properties including `isScrolled`
 * and the content to wrap.
 * @returns {JSX.Element} - The JSX for the sticky wrapper.
 */
const StickyWrapper: React.FC<StickyWrapperProps> = ({
  isScrolled,
  children,
}) => (
  <div className={`menu-area${isScrolled ? " menu-area sticky-menu" : ""}`}>
    {children}
  </div>
);

// Interface for HeaderSticky component props
interface HeaderStickyProps {
  isScrolled: boolean;
  mobile: boolean;
  setMobile: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * HeaderSticky Component
 *
 * Renders the sticky version of the header when the page is scrolled.
 * It includes the logo, navigation menu, and WhatsApp button for quick contact.
 *
 * @param {HeaderStickyProps} props - The props for determining scroll state,
 * mobile menu state, and toggle function.
 * @returns {JSX.Element} - The JSX for the sticky header.
 */
const HeaderSticky: React.FC<HeaderStickyProps> = ({
  isScrolled,
  mobile,
  setMobile,
}) => (
  <StickyWrapper isScrolled={isScrolled}>
    <div className="container">
      <div className="second-menu">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-2">
            <HeaderLogo />
          </div>
          <div className="col-xl-6 col-lg-7">
            <Menu />
          </div>
          <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block">
            <Button
              href={WHATSAPP_LINK}
              containerClassName="second-header-btn"
              buttonClassName="btn"
              leftIconClassName="fab fa-whatsapp icon-mr"
            />
          </div>
          <div className="col-12">
            <MobileMenu mobile={mobile} setMobile={setMobile} />
          </div>
        </div>
      </div>
    </div>
  </StickyWrapper>
);

/**
 * HeaderTop Component
 *
 * Renders the top section of the header. This section includes the social media links
 * and contact information and is only visible on medium or larger screens.
 *
 * @returns {JSX.Element} - The JSX for the top section of the header.
 */
const HeaderTop: React.FC = () => (
  <div className="header-top second-header d-none d-md-block">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-4 col-md-4 d-none d-lg-block">
          <HeaderSocialLinks />
        </div>
        <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
          <HeaderCTA />
        </div>
      </div>
    </div>
  </div>
);

/**
 * HeaderLogo Component
 *
 * Renders the logo of the website in the header, linking to the homepage.
 *
 * @returns {JSX.Element} - The JSX for the logo section of the header.
 */
const HeaderLogo: React.FC = () => (
  <div className="logo">
    <Link to="/">
      <img src={LOGO_IMAGE} alt="logo" />
    </Link>
  </div>
);

// Interface for NavItem component props
interface NavItemProps {
  link: string;
  label: string;
}

/**
 * NavItem Component
 *
 * Renders a single navigation item (link) in the menu.
 *
 * @param {NavItemProps} props - The properties for the navigation item such as
 * the link and label.
 * @returns {JSX.Element} - The JSX for a navigation item.
 */
const NavItem: React.FC<NavItemProps> = ({ link, label }) => (
  <li>
    <Link to={link}>{label}</Link>
  </li>
);

// Interface for MenuContainer component props
interface MenuContainerProps {
  children: React.ReactNode;
}

/**
 * MenuContainer Component
 *
 * A container for wrapping the list of navigation items in the main menu.
 *
 * @param {MenuContainerProps} props - The properties including the children to render inside the container.
 * @returns {JSX.Element} - The JSX for the menu container.
 */
const MenuContainer: React.FC<MenuContainerProps> = ({ children }) => (
  <div className="main-menu text-right text-xl-right">
    <nav id="mobile-menu">
      <ul>{children}</ul>
    </nav>
  </div>
);

/**
 * Menu Component
 *
 * Renders the main navigation menu, which includes a list of navigation items
 * defined in `navigationItems`.
 *
 * @returns {JSX.Element} - The JSX for the main menu.
 */
const Menu: React.FC = () => (
  <MenuContainer>
    {navigationItems.map((item, index) => (
      <NavItem key={index} link={item.to} label={item.label} />
    ))}
  </MenuContainer>
);

// Interface for MobileMenuButton component props
interface MobileMenuButtonProps {
  isMobileMenuOpen: boolean;
  toggleMobileMenu: (isOpen: boolean) => void;
}

/**
 * MobileMenuButton Component
 *
 * Renders the button to toggle the mobile menu. The button displays either an "X" when
 * the menu is open or a hamburger icon when it's closed.
 *
 * @param {MobileMenuButtonProps} props - The properties including whether the mobile menu
 * is open and the function to toggle it.
 * @returns {JSX.Element} - The JSX for the mobile menu button.
 */
const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({
  isMobileMenuOpen,
  toggleMobileMenu,
}) => (
  <a
    onClick={() => toggleMobileMenu(!isMobileMenuOpen)}
    className={`meanmenu-reveal ${isMobileMenuOpen ? "meanclose" : ""}`}
    style={buttonStyle}
  >
    {isMobileMenuOpen ? (
      "X"
    ) : (
      <span>
        <span>
          <span></span>
        </span>
      </span>
    )}
  </a>
);

// Custom styles for the mobile menu button
const buttonStyle: React.CSSProperties = {
  right: 0,
  left: "auto",
  textAlign: "center",
  textIndent: 0,
  fontSize: 18,
};

// Interface for MobileMenuContent component props
interface MobileMenuContentProps {
  mobile: boolean;
}

/**
 * MobileMenuContent Component
 *
 * Renders the content (list of navigation items) inside the mobile menu.
 * The content is displayed when `mobile` is true.
 *
 * @param {MobileMenuContentProps} props - The properties indicating whether the mobile
 * menu is open.
 * @returns {JSX.Element | null} - The JSX for mobile menu content or null if not open.
 */
const MobileMenuContent: React.FC<MobileMenuContentProps> = ({ mobile }) => {
  return mobile ? (
    <nav className="mean-nav">
      <ul style={{ display: "block" }}>
        {navigationItems.map((navigationItem, index) => (
          <li key={index}>
            <Link to={navigationItem.to}>{navigationItem.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
};

// Interface for MobileMenu component props
interface MobileMenuProps {
  mobile: boolean;
  setMobile: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * MobileMenu Component
 *
 * Renders the entire mobile menu, including the button to toggle the menu
 * and the content inside the menu.
 *
 * @param {MobileMenuProps} props - The properties including whether the mobile
 * menu is open and the function to toggle it.
 * @returns {JSX.Element} - The JSX for the mobile menu.
 */
const MobileMenu: React.FC<MobileMenuProps> = ({ mobile, setMobile }) => (
  <div className="mobile-menu mean-container">
    <div className="mean-bar">
      <MobileMenuButton
        isMobileMenuOpen={mobile}
        toggleMobileMenu={setMobile}
      />
      <MobileMenuContent mobile={mobile} />
    </div>
  </div>
);

/**
 * Header Component
 *
 * The main header component that combines the top section, sticky menu,
 * logo, and mobile menu. It also handles scroll behavior using the `useHeaderState` hook.
 *
 * @returns {JSX.Element} - The JSX for the header component.
 */
const Header: React.FC = () => {
  const { mobile, setMobile, isScrolled } = useHeaderState();

  return (
    <header className="header-area header-three">
      <HeaderTop />
      <HeaderSticky
        isScrolled={isScrolled}
        mobile={mobile}
        setMobile={setMobile}
      />
    </header>
  );
};

export default Header;
