import React from "react";
import { INSIGHT_CARD_05 } from "../../../constants/images/images.c";

const UpperBox = () => {
  return (
    <div className="upper-box">
      <div className="single-item-carousel owl-carousel owl-theme">
        <figure className="image">
          <img src={INSIGHT_CARD_05} alt="Investimentos" />
        </figure>
      </div>
    </div>
  );
};

const LowerContent = () => {
  return (
    <div className="lower-content2">
      <div className="row">
        <div className="text-column col-lg-9 col-md-12 col-sm-12">
          <div
            className="s-about-content wow fadeInRight"
            data-animation="fadeInRight"
            data-delay=".2s"
          >
            <h2>
              Renda fixa: Como Aproveitar Juros Altos e Proteger Seu Capital
            </h2>
            <p>
              Os investimentos em renda fixa, geralmente, correspondem por uma
              parcela relevante de um portfólio, seja dos investidores mais
              conservadores, como, também, dos investidores mais arrojados.
              <strong>
                {" "}
                Manter recursos em títulos de renda fixa de alta qualidade
                proporciona a obtenção de retornos com baixa volatilidade.{" "}
              </strong>
            </p>
            <p>
              Já no Brasil, a história é diferente.{" "}
              <strong>
                Historicamente o Brasil apresenta uma taxa real de juros elevada
              </strong>
              que, por um lado, mostra-se como um peso para o setor produtivo e
              para a dívida pública, mas, por outro lado, apresenta-se como uma{" "}
              <strong>oportunidade para os investidores</strong>.
            </p>
            <p>
              Além de aproveitar uma taxa de juros real elevada no mercado
              brasileiro, destacam-se as seguintes vantagens potenciais que
              <strong>
                {" "}
                os títulos de renda fixa podem agregar a um portfólio de
                investimentos:
              </strong>
            </p>
            <ol>
              <li>
                <strong>Diversificação</strong> <br />
                <p>
                  Os títulos de renda fixa costumam ter uma baixa correlação em
                  relação à outras classes de ativos.
                </p>
              </li>
              <li>
                <strong>Fluxo de caixa regular</strong> <br />
                <p>
                  Os diversos títulos de renda fixa disponíveis têm uma
                  característica em comum, que é o estabelecimento das regras de
                  pagamento no momento da sua emissão.
                </p>
              </li>
              <li>
                <strong>Potencial proteção contra a inflação</strong> <br />
                <p>
                  Alguns títulos de renda fixa são indexados a índices de
                  inflação, proporcionando, além de uma taxa de juros real, a
                  proteção inflacionária calculada a partir do índice escolhido.
                </p>
              </li>
              <li>
                <strong>Liquidez</strong> <br />
                <p>
                  Alguns títulos de renda fixa proporcionam liquidez diária aos
                  investidores, o que possibilita a obtenção de rentabilidade
                  com a vantagem de manter recursos com liquidez.
                </p>
              </li>
            </ol>
            <p>
              Ao contrário do que muitas vezes se pensa, os títulos de renda
              fixa também apresentam riscos, sendo que os principais são: risco
              de taxa de juros e risco de crédito.
            </p>
            <p>
              Além dos títulos públicos, também é possível investir em títulos
              de renda fixa emitidos por instituições financeiras ou empresas
              privadas.
            </p>
            <p>
              Em síntese, o investimento em títulos de renda fixa apresenta uma
              série de detalhes, proporcionando diferentes expectativas de
              retorno, a depender do risco e nível de liquidez que esteja sendo
              assumido. Neste sentido, a <strong>Ostrya Investimentos</strong>{" "}
              possui profissionais tecnicamente qualificados e com larga
              experiência no mercado financeiro para estruturar o portfólio de
              investimentos mais adequado ao investidor e acompanhá-lo nessa
              jornada no mercado financeiro.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Fifth = () => {
  return (
    <section className="project-detail">
      <div className="container">
        <UpperBox />
        <LowerContent />
      </div>
    </section>
  );
};

export default Fifth;
