import React from "react";
import { Link } from "react-router-dom";

/**
 * Props for the Icon component.
 * Defines the class names for the icon and any additional classes.
 */
interface IconProps {
  iconClassName: string;
  extraClassName?: string;
}

/**
 * Component responsible for rendering an icon with optional additional classes.
 *
 * @param iconClassName - The class for the icon.
 * @param extraClassName - Additional class for the icon (default is "btn-m").
 * @returns JSX.Element that represents an icon.
 */
const Icon: React.FC<IconProps> = ({
  iconClassName,
  extraClassName = "btn-m",
}) => <i className={`${iconClassName} ${extraClassName}`} />;

/**
 * Props for the Button component.
 * Defines all the possible props for the button, including its type, links, icons, and text.
 */
interface ButtonProps {
  component?: "a" | "link" | "button";
  to?: string;
  href?: string;
  buttonClassName?: string;
  containerClassName?: string;
  leftIconClassName?: string;
  rightIconClassName?: string;
  buttonText?: string;
  linkTarget?: string;
  linkRel?: string;
  type?: "button" | "submit" | "reset";
  onClick?: React.MouseEventHandler<HTMLButtonElement>; // Fix the type here
}

/**
 * Component responsible for rendering different types of buttons, links, or anchor tags.
 *
 * @param component - The type of component to render: "a", "link", or "button".
 * @param to - The target path for the Link component.
 * @param href - The target URL for the anchor element.
 * @param buttonClassName - The CSS class for the button.
 * @param containerClassName - The CSS class for the button container.
 * @param leftIconClassName - The CSS class for the left icon.
 * @param rightIconClassName - The CSS class for the right icon.
 * @param buttonText - The text displayed inside the button.
 * @param linkTarget - Target attribute for anchor tags (default is "_blank").
 * @param linkRel - Rel attribute for anchor tags (default is "noopener noreferrer").
 * @param type - The type attribute for the button (default is "button").
 * @param onClick - The click handler for the button.
 * @returns JSX.Element that represents a button, link, or anchor tag with optional icons and text.
 */
const Button: React.FC<ButtonProps> = ({
  component = "a",
  to = "",
  href,
  buttonClassName = "btn",
  containerClassName = "",
  leftIconClassName = "",
  rightIconClassName = "",
  buttonText = "Fale Conosco",
  linkTarget = "_blank",
  linkRel = "noopener noreferrer",
  type = "button",
  onClick, // Remove null default value to avoid error
}) => {
  return (
    <div className={containerClassName}>
      {component === "a" ? (
        <a
          href={href}
          className={buttonClassName}
          target={linkTarget}
          rel={linkRel}
        >
          {leftIconClassName && <Icon iconClassName={leftIconClassName} />}
          {buttonText}
          {rightIconClassName && <Icon iconClassName={rightIconClassName} />}
        </a>
      ) : component === "link" ? (
        <Link to={to} className={buttonClassName}>
          {leftIconClassName && <Icon iconClassName={leftIconClassName} />}
          {buttonText}
          {rightIconClassName && <Icon iconClassName={rightIconClassName} />}
        </Link>
      ) : (
        <button type={type} className={buttonClassName} onClick={onClick}>
          {leftIconClassName && <Icon iconClassName={leftIconClassName} />}
          {buttonText}
          {rightIconClassName && <Icon iconClassName={rightIconClassName} />}
        </button>
      )}
    </div>
  );
};

export default Button;
