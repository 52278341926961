import React from "react";
import { MENTORING_IMAGE } from "../../constants/images/images.c";

// Interface for the props used in the ServiceItem component
interface ServiceItemProps {
  iconClass: string;
  title: string;
  description: string;
}

/**
 * ServiceItem Component
 *
 * This component renders an individual service item with an icon, title, and description.
 *
 * @param {ServiceItemProps} props - Contains the icon class, title, and description for the service item.
 * @returns {JSX.Element} - The JSX for an individual service item.
 */
const ServiceItem: React.FC<ServiceItemProps> = ({
  iconClass,
  title,
  description,
}) => {
  return (
    <div className="col-lg-6 animated wow fadeInUp">
      <div className="service-item d-flex flex-column flex-sm-row bg-oc-primary rounded h-100 p-4 p-lg-5">
        <div className="bg-icon flex-shrink-0 mb-3">
          <i className={`fa ${iconClass} fa-2x text-oc-secondary`}></i>
        </div>
        <div className="ms-sm-4">
          <h4 className="mb-3 text-white">{title}</h4>
          <span className="text-white">{description}</span>
        </div>
      </div>
    </div>
  );
};

/**
 * ServiceList Component
 *
 * This component renders a list of service items. The service data is defined in the array
 * and rendered dynamically using the `map` method.
 *
 * @returns {JSX.Element} - The JSX for the list of services displayed on the page.
 */
const ServiceList: React.FC = () => {
  const services: ServiceItemProps[] = [
    {
      iconClass: "fa-book-open",
      title: "Desenvolvimento do Investidor",
      description:
        "Por meio de um programa de indicações de conteúdos, a mentoria visa aprimorar as habilidades técnicas e comportamentais do investidor. São recomendados cursos, livros, podcasts, vídeos e outros recursos exclusivos que permitem ao investidor expandir seu conhecimento e refinar suas estratégias de investimento.",
    },
    {
      iconClass: "fa-chart-line",
      title: "Estratégia Customizada",
      description:
        "A mentoria inclui a estruturação de um plano de investimentos personalizado, cuidadosamente elaborado para atender às suas necessidades individuais. Esse plano considera o perfil, os objetivos e as restrições de cada investidor.",
    },
    {
      iconClass: "fa-user-tie",
      title: "Consultor Exclusivo",
      description:
        "O investidor terá acesso direto a um consultor dedicado, que acompanhará de perto o seu progresso. Com reuniões periódicas, esse consultor oferece suporte contínuo, ajustando estratégias conforme necessário e orientando o investidor em cada etapa do processo.",
    },
    {
      iconClass: "fa-video",
      title: "Reuniões Online",
      description:
        "A mentoria também inclui encontros online regulares, individuais e em grupo, nos quais são discutidos temas técnicos sobre investimentos, análises de ativos e tendências de mercado. Esses encontros são essenciais para manter o investidor informado e preparado para tomar decisões estratégicas.",
    },
  ];

  return (
    <div className="row g-4">
      {services.map((service, index) => (
        <ServiceItem
          key={index}
          iconClass={service.iconClass}
          title={service.title}
          description={service.description}
        />
      ))}
    </div>
  );
};

/**
 * Mentoring Component
 *
 * The main component for rendering the mentoring section on the page.
 * It includes the ServiceList component that renders the service items and a mentoring image.
 *
 * @returns {JSX.Element} - The JSX for the mentoring section.
 */
const Mentoring: React.FC = () => {
  return (
    <div
      className="container-fluid animated wow fadeInUp bg-white my-5"
      id="service"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="row mb-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-6">
              <h2 className="section-title display-5 mb-0">Mentoria</h2>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <ServiceList />
          </div>
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            <img
              src={MENTORING_IMAGE}
              alt="Placeholder"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentoring;
