import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
import Methodology from "../components/Methodology/Methodology";
import Importance from "../components/Importance/Importance";
import Problems from "../components/Problems/Problems";
import Insights from "../components/Insights/Insights";

const InvestmentsConsultingPage = () => {
  return (
    <>
      <WhyChooseUs />
      <Methodology />
      <Importance />
      <Problems />
      <Insights />
    </>
  );
};
export default InvestmentsConsultingPage;
