interface NavigationItem {
  to: string;
  label: string;
}

export const navigationItems: NavigationItem[] = [
  { to: "/", label: "Início" },
  {
    to: "/consultoria-de-investimentos",
    label: "Consultoria de Investimentos",
  },
  { to: "/mentoria", label: "Mentoria" },
  { to: "/planejamento-sucessorio", label: "Planejamento Sucessório" },
];
