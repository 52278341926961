import React from "react";
import { METHODOLOGY_IMAGE } from "../../constants/images/images.c";

// Interface for ServiceItem props
interface ServiceItemProps {
  icon: string;
  title: string;
  description: string;
  delay: string;
}

// Data for service items
const serviceItemsData: ServiceItemProps[] = [
  {
    icon: "fa-user-tie",
    title: "Análise Inicial do Perfil e Portfólio do Investidor",
    description: "Entendemos suas metas e tolerância ao risco.",
    delay: "0.1s",
  },
  {
    icon: "fa-chart-line",
    title: "Estruturação do Portfólio",
    description: "Estruturação de portfólio adequado ao perfil do investidor.",
    delay: "0.3s",
  },
  {
    icon: "fa-tasks",
    title: "Implementação e Acompanhamento",
    description:
      "Acompanhamento da implementação do plano e do desempenho do portfólio.",
    delay: "0.1s",
  },
  {
    icon: "fa-sync-alt",
    title: "Reavaliação e Rebalanceamento",
    description: "Mantemos sua carteira ajustada às suas metas.",
    delay: "0.3s",
  },
];

/**
 * ServiceItem Component
 *
 * This component renders a service item with an icon, title, and description.
 * Each service item appears with a fade-in animation and a delay for the animation effect.
 *
 * @param {ServiceItemProps} props - Contains the icon class, title, description, and animation delay.
 * @returns {JSX.Element} - The JSX for an individual service item.
 */
const ServiceItem: React.FC<ServiceItemProps> = ({
  icon,
  title,
  description,
  delay,
}) => {
  return (
    <div className={`col-lg-6 wow fadeInUp`} data-wow-delay={delay}>
      <div className="service-item d-flex flex-column flex-sm-row bg-oc-primary rounded h-100 p-4 p-lg-5">
        <div className="bg-icon flex-shrink-0 mb-3">
          <i className={`fa ${icon} fa-2x text-oc-secondary`}></i>
        </div>
        <div className="ms-sm-4">
          <h4 className="mb-3 text-white">{title}</h4>
          <span className="text-white">{description}</span>
        </div>
      </div>
    </div>
  );
};

/**
 * ServiceSection Component
 *
 * This component renders the section that includes the service items and a methodology image.
 * The service items are dynamically rendered using the `map` function based on the `serviceItemsData` array.
 *
 * @returns {JSX.Element} - The JSX for the service section including the title and service items.
 */
const ServiceSection: React.FC = () => {
  return (
    <div
      className="container-fluid animated wow fadeInUp bg-white py-5 mb-5"
      id="service"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            <img
              src={METHODOLOGY_IMAGE}
              alt="Metodologia"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-8">
            <div className="row g-5 mb-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="col-lg-6">
                <h2 className="section-title display-5 mb-0">Metodologia</h2>
              </div>
            </div>
            <div className="row g-4">
              {serviceItemsData.map((item, index) => (
                <ServiceItem
                  key={index}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  delay={item.delay}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Methodology Component
 *
 * The main component for rendering the methodology section on the page.
 * It utilizes the ServiceSection component to render the services and methodology image.
 *
 * @returns {JSX.Element} - The JSX for the methodology section.
 */
const Methodology: React.FC = () => {
  return <ServiceSection />;
};

export default Methodology;
