import React from "react";
import { IMPORTANCE_IMAGE } from "../../constants/images/images.c";
import { WHATSAPP_LINK } from "../../constants/links/links.c";

// Data for Call to Action button
const callToActionData = {
  href: WHATSAPP_LINK,
  text: "Entenda Melhor suas Opções de Investimento",
};

/**
 * ImageSection Component
 *
 * Renders the image section with the provided image.
 *
 * @returns {JSX.Element} - The JSX for the image section.
 */
const ImageSection: React.FC = () => (
  <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
    <img src={IMPORTANCE_IMAGE} alt="Imagem Descrição" className="img-fluid" />
  </div>
);

/**
 * TextSection Component
 *
 * Renders the text section with a title and description, including a highlighted section.
 * It also includes the CallToAction component.
 *
 * @returns {JSX.Element} - The JSX for the text section.
 */
const TextSection: React.FC = () => (
  <div className="col-lg-6 col-md-12">
    <h2>A importância de um Consultor Independente</h2>
    <p>
      Em muitos casos, o mesmo produto de investimento apresenta rendimentos
      diferentes em cada banco. Aqui, nosso{" "}
      <span className="fw-bold text-oc-secondary">
        consultor não está relacionado a bancos ou corretoras
      </span>
      , o que significa que você receberá uma análise imparcial e personalizada.
      Com total independência, os consultores examinam essas variações e
      garantem que você escolha a opção mais vantajosa, sempre de acordo com seu
      perfil e objetivos financeiros.
    </p>
    <CallToAction />
  </div>
);

/**
 * CallToAction Component
 *
 * Renders a call-to-action button using the `callToActionData` object for the link and text.
 *
 * @returns {JSX.Element} - The JSX for the call-to-action button.
 */
const CallToAction: React.FC = () => (
  <div className="col-lg-4">
    <div
      className="cta-btn s-cta-btn wow fadeInRight animated"
      data-animation="fadeInDown animated"
    >
      <a
        href={callToActionData.href}
        target="_blank"
        rel="noopener noreferrer"
        className="btn ss-btn smoth-scroll"
      >
        {callToActionData.text}
      </a>
    </div>
  </div>
);

/**
 * HiddenSection Component
 *
 * Renders the hidden section with the image and text sections.
 * This section includes a fade-in animation and custom styling for opacity and transition.
 *
 * @returns {JSX.Element} - The JSX for the hidden section with animation.
 */
const HiddenSection: React.FC = () => (
  <div
    className="hidden-section animated fadeInUp"
    style={{
      transition: "opacity 0.5s ease-out",
      opacity: 1,
      animationDuration: "3s",
    }}
  >
    <div className="container animated wow fadeInUp my-5">
      <div className="row align-items-center">
        <ImageSection />
        <TextSection />
      </div>
    </div>
  </div>
);

/**
 * Importance Component
 *
 * The main component that renders the HiddenSection. It serves as the wrapper for the
 * image and text sections and manages the hidden section visibility and animation.
 *
 * @returns {JSX.Element} - The JSX for the importance section.
 */
const Importance: React.FC = () => (
  <div>
    <HiddenSection />
  </div>
);

export default Importance;
