import { INSTRAGRAM_LINK, LINKEDIN_LINK, WHATSAPP_LINK } from "../links/links.c";

// Interface for social link props
export interface SocialLink {
  href: string;
  title: string;
  iconClass: string;
}

// Social links data, which includes the href, title, and icon class for each social media platform.
export const socialLinks: SocialLink[] = [
  {
    href: INSTRAGRAM_LINK,
    title: "Instagram",
    iconClass: "fab fa-instagram",
  },
  {
    href: LINKEDIN_LINK,
    title: "LinkedIn",
    iconClass: "fab fa-linkedin",
  },
  {
    href: WHATSAPP_LINK,
    title: "Whatsapp",
    iconClass: "fab fa-whatsapp",
  },
];
