import {
  SERVICE_ICON_01,
  SERVICE_ICON_02,
  SERVICE_ICON_03,
} from "../images/images.c";

export interface ServiceItem {
  to: string;
  icon: string;
  title: string;
  description: string;
}

export const serviceItems: ServiceItem[] = [
  {
    to: "/consultoria-de-investimentos",
    icon: SERVICE_ICON_01,
    title: "Consultoria de Investimentos",
    description:
      "Trabalhamos com diversos ativos, como títulos de renda fixa, ações, fundos, e derivativos, para clientes no Brasil e no exterior.",
  },
  {
    to: "/mentoria",
    icon: SERVICE_ICON_02,
    title: "Mentoria",
    description:
      "Acompanhamento direto com o consultor, abordando habilidades técnicas e comportamentais essenciais para decisões financeiras.",
  },
  {
    to: "/planejamento-sucessorio",
    icon: SERVICE_ICON_03,
    title: "Planejamento Sucessório",
    description:
      "Ajudamos a estruturar seu patrimônio, desde a escolha de investimentos até a criação de uma holding para facilitar a sucessão.",
  },
];
